<template lang="">
  <div class="contact">
    <Banner :title="contact"></Banner>
    <Title :title="contact"></Title>
    <div class="wei-container">
      <!-- <baidu-map
        class="bmView"
        :scroll-wheel-zoom="true"
        :center="location"
        :zoom="zoom"
        ak="C81lgBx3OBkQG9yjlYrmlgLEqXY6YGVw"
        style="width: 100%; height:400px; flex: 1"
      >
        <bm-view style="width: 100%; height:400px; flex: 1"></bm-view>
      </baidu-map> -->
      <div class="company_info flex-row-top">
        <div class=" infomation">
          <div class="line"></div>
          <div class="cname">{{ weidiansiInfo.company }}</div>
          <div class="ename">{{ weidiansiInfo.companyEn }}</div>
          <div class="box" v-if="contactInfo.list">
            <div class="item">
              {{ contactInfo.list[0] }}{{ companyInfo.kfqq }}
            </div>
            <div class="item">
              {{ contactInfo.list[1] }}{{ companyInfo.kfdh }}
            </div>
            <div class="item">
              {{ contactInfo.list[2] }}{{ companyInfo.lxsj }}
            </div>
            <div class="item">
              {{ contactInfo.list[3] }}{{ companyInfo.dbdz }}
            </div>
             <div class="item">
              {{ contactInfo.list[4] }} <a :href="companyInfo.scwz" class="hover" target="_blank">{{ companyInfo.scwz }}</a>
            </div>
          </div>
        </div>
        <div class="focus ">
          <div class="line"></div>
          <div class="name">{{ focus.name }}</div>
          <div class="qrcode">
            <img :src="imgUploadUrl + companyInfo.wxgzh" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">{{ copyright.name }}</div>
  </div>
</template>
<script>
import Banner from "@/components/Banner";
import Title from "@/components/title";
// import BaiduMap from "vue-baidu-map/components/map/Map.vue";
// import BmView from "vue-baidu-map/components/map/MapView.vue";
// import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    Banner,
    Title,
    // BaiduMap,
    // BmView,
    // BmLocalSearch,
  },
  data() {
    return {
      weidiansiInfo: {},
      contact: {},
      contactInfo: {},
      focus: {},
      copyright: {},
      location: {
        lng: 116.404,
        lat: 39.915,
      },
      zoom: 12.8,
    };
  },
  computed: {
    ...mapGetters(["companyInfo",'imgUploadUrl']),
  },
  created() {
    this.weidiansiInfo = this.$t("weidiansiInfo");
    this.contact = this.$t("contact");
    this.focus = this.$t("footer.focus");
    this.contactInfo = this.$t("footer.contact");
    this.copyright = this.$t("footer.copyright");
    this.SET_FOOTER_SHOW(false)
  },
  destroyed() {
     this.SET_FOOTER_SHOW(true)
  },
  methods:{
    ...mapMutations(['SET_FOOTER_SHOW'])
  }
};
</script>
<style lang="scss">
.contact {
  background-color: #fff;
  .bmView {
    margin-bottom: 120px;
  }
  .company_info {
    justify-content: space-between;
    text-align: left;
    font-weight: normal;
    font-family: Adobe Heiti Std;
    color: #aaaaaa;
    .line {
      width: 168px;
      height: 2px;
      background: #e6e6e6;
      margin-bottom: 40px;
    }
    .cname {
      font-size: 24px;
      color: #19222f;
    }
    .ename {
      font-size: 12px;
      margin-top: 10px;
    }
    .box {
      margin-top: 40px;
      .item {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    .focus {
      .name {
        font-size: 24px;
        color: #19222f;
        margin-bottom: 40px;
      }
      img {
        width: 198px;
        height: 198px;
      }
    }
  }
  .copyright {
    margin-top: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    color: #525a66;
    font-family: Microsoft YaHei;
    color: #aaaaaa;
  }
}
</style>
